<template>
	<pop-up-wrapper>
		<div class="pop-up-edit-key">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Enregistrer votre activité</p>
			<p>
				Pour enregistrer votre activité, sélectionnez une classe et un
				créneau.
			</p>
			<div class="input-container first">
				<label for="classes">Classe</label>
				<select id="classes" v-model="form.classes">
					<option
						selected="selected"
						v-if="exercise.classes"
						:value="exercise.classes"
					>
						{{ getClasse(exercise.classes) }}
					</option>
					<option
						v-for="classe in $store.state.classes"
						:key="classe.id"
						:value="classe.id"
					>
						{{ classe.name }}
					</option>
				</select>
				<small class="error">{{ errors.classe }}</small>
			</div>
			<div class="input-container">
				<label for="date">Créneau</label>
				<select
					v-if="form.classes.length !== 0"
					v-model="form.currency"
					id="date"
				>
					<option
						v-for="course in getCourses"
						:key="course.id"
						:value="course.id"
					>
						{{ course.formatedDay }} {{ course.debut_hour }} -
						{{ course.end_hour }}
					</option>
				</select>
				<select v-else name="" id=""></select>
			</div>
			<button @click="submit" class="submit dark">Attribuer</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { allActivityEventsFilterService } from '../../../../services/activityEventsService'
import { getOccurenceByIdService, updateOccurenceService } from '../../../../services/occurrencesService'
import { createTileGameContentOrderService } from '../../../../services/tileGameContentsOrderService'
import { createTileGameService } from '../../../../services/tileGamesService'
import { checkRightFor, formateAndOrderDate } from '../../../../utils'
import PopUpWrapper from '../../PopUpWrapper.vue'

export default {
	components: {
		PopUpWrapper
	},
	props: {
		exoInCalendar: Array,
	},
	data() {
		return {
			form: {
				classes: [],
				currency: null
			},
			key: {},
			index: 0,
			exercise: {},
			classes: [],
			questionsList: [],
			teacher: localStorage.userId,
			rituals: [],
			courses: [],
			agenda: {},
			errors: {}
		}
	},

	methods: {
		async allCourses() {
			let classes = []
			for (const classe of this.$store.state.classes) {
				classes.push(classe.id)
			}
			if (classes.length !== 0) {
				const filter = `?classes=${classes}`
				const res = await allActivityEventsFilterService(filter)
				this.courses = formateAndOrderDate(res)
				if (this.$store.state.selectedOccurrence) {
					this.form.classes = this.$store.state.selectedOccurrence.classId
					const occurrenceId = this.$store.state.selectedOccurrence.occurrenceId
					this.form.currency = occurrenceId
				}
			}
		},
		close() {
			this.$emit('close')
		},
		async submit() {
			if (!checkRightFor('Jeux Sérieux', [parseInt(this.$route.params.level_id)])) {
				this.$store.state.isPopUpNotAllowedVisible = true
				return
			}
			if (this.form.classes.length !== 0) {
				let classArray = this.form.classes
				const data = {
					level_id: this.$route.params.level_id,
					class_id: classArray,
					theme: this.$route.params.theme,
					teacher: this.$store.state.user.user,
				}

				const res = await createTileGameService(data)
				for await (const exo of this.exoInCalendar) {
					const data = {
						tile_game_id: res.id,
						tile_game_content_id: exo.id,
						pos: exo.list
					}
					await createTileGameContentOrderService(data)
				}
				if (this.form.currency) {
					let occurrence = await getOccurenceByIdService(this.form.currency)
					occurrence.tile_games.push(res.id)
					await updateOccurenceService(this.form.currency, occurrence)
				}
				this.$router.push({ name: 'ClassResources', params: { id: classArray } })
			}
			else {
				this.$store.state.toast = {
					status: 'error',
					text: 'Veuillez ajouter une classe à votre ressource'
				}
			}
		},
		getClasse(e) {
			if (this.classes.length !== 0) {
				let classe = this.classes.find(x => x.id == e)
				return classe.name
			}
		},
	},
	computed: {
		getCourses() {
			let courses = this.courses.filter(x => x.classroom == this.form.classes)
			return courses
		}
	},
	created() {
		this.allCourses()
	},
}
</script>

<style>
</style>

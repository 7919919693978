<template>
	<section class="price-list-container">
		<h1>Des contenus à la carte</h1>
		<p class="choose-text">Choisissez selon votre usage</p>
		<form @submit.prevent="submit">
			<div class="each-price-container" v-if="lineItems">
				<each-price
					v-for="product in lineItems"
					:key="product.name"
					:product="getMatchingProduct(product.name)"
					:isAlreadyOwned="checkIfAlreadyOwned(product.name)"
					@updateSelected="setSelected"
				/>
			</div>
			<div class="price-list-footer">
				<div class="info-container">
					<p>
						Si vous achetez 3 ressources, la 4ème vous est offerte
					</p>
					<p
						v-if="selectedProducts.length == 3"
						class="gived"
						v-html="checkMissingProduct()"
					></p>
				</div>
				<div class="payment-container">
					<p v-html="getPrice()"></p>
					<stripe-checkout
						v-if="isStripeActive"
						ref="checkoutRef"
						mode="subscription"
						:sessionId="sessionId"
						:pk="publishableKey"
						@loading="(v) => (loading = v)"
					/>
					<button class="submit dark">Accéder au paiement</button>
				</div>
			</div>
		</form>
	</section>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import { authenticatedApiFetch, authenticatedFetch } from '../../../utils'
import axios from 'axios'
import Config from '../../../config'
import EachPrice from './EachPrice.vue'
export default {
	components: {
		StripeCheckout,
		EachPrice,
	},
	data() {
		return {
			sessionId: '',
			discounts: [{
				'coupon': 'AlrDWAyf'
			}],
			publishableKey: Config.publishable_key,
			lineItems: [

			],
			products: [
				{
					img: '/img/payment/flash-icon.svg',
					title: 'Questions Flash',
					text: 'Préparez votre diaporama Flash en 3 min montre en main!',
					color: '#FFCC24'
				},
				{
					img: '/img/payment/expresso-icon.svg',
					title: 'Activités Expresso',
					text: 'Motivez vos élèves avec nos activités clés en main au design léché.',
					color: '#D11AFC'
				},
				{
					img: '/img/payment/game-icon.svg',
					title: 'Jeux Sérieux',
					text: 'Fiches d\'exercices ou d\'évaluation, réalisez votre document en un rien de temps… Puis vidéo-projetez ou imprimez !',
					color: '#59CA24'
				},
				{
					img: '/img/payment/classico-icon.svg',
					title: 'Exercices Classico',
					text: 'A la fin d\'un cours ou avant les vacances, captivez vos élèves et transmettez de façon ludique et pédagogique !',
					color: '#31C3D6'
				},
			],


			selectedProducts: [

			],
			isStripeActive: false,
			alreadyOwnedProducts: [],
			isLoaded: false
		}
	},
	methods: {

		checkMissingProduct() {
			// Créer un ensemble pour stocker les titres de array1
			const selectedProducts = new Set(this.selectedProducts.map(item => item.name))

			// Trouver les titres manquants dans array2 par rapport à array1
			const missingProduct = this.lineItems.find(item => !selectedProducts.has(item.name))
			// return missingProduct
			let className
			switch (missingProduct.name) {
				case 'Questions Flash':
					className = 'flash'
					break
				case 'Activités Expresso':
					className = 'expresso'
					break
				case 'Exercices Classico':
					className = 'classico'
					break
				case 'Jeux Sérieux':
					className = 'game'
					break
				default:
					className = 'flash'
			}


			return `Les <span class="${className}">${missingProduct.name}</span> sont
						offert${missingProduct.name == 'Activités Expresso' || missingProduct.name == 'Questions Flash' ? 'e' : ''}s, sélectionnez les`


		},
		setSelected(e) {
			const product = this.lineItems.find(x => x.name == e)
			const index = this.selectedProducts.indexOf(product)

			if (index === -1) {
				// L'élément n'est pas présent dans le tableau, donc on l'ajoute
				this.selectedProducts.push(product)
			} else {
				// L'élément est déjà présent dans le tableau, donc on le supprime
				this.selectedProducts.splice(index, 1)
			}

		},
		getMatchingProduct(name) {
			const product = this.products.find(x => x.title == name)
			return product
		},
		async submit() {
			this.isStripeActive = true
			const data = {
				line_items: this.getCleanedProducts()
			}
			const res = await authenticatedFetch(`/get-session/`, { method: 'POST', body: JSON.stringify(data) })
				.then(res => res.json())
			this.sessionId = res.session_id

			this.$refs.checkoutRef.redirectToCheckout({
				metadata: {
					product_id: 'your_product_id',
					user_id: 'user_id',
				},
			})
		},
		getPrice() {
			let price = 0
			const productsLength = this.selectedProducts.length
			price = productsLength * 72
			if (this.selectedProducts.length == 4) {
				return `Total :<s> ${price}€</s> ${price - 72}€`
			}
			return `Total : ${price}€`


		},
		async getProducts() {
			const res = await axios.get(`${Config.backend_url}/api/v1/products/`)
			this.lineItems = res.data
		},
		getCleanedProducts() {
			const product = this.selectedProducts.map(item => {
				const { id, name, price_number, ...rest } = item
				console.log(name)
				console.log(id)
				console.log(price_number)
				return rest
			})
			return product
		},
		async getAlreadyOwnedProducts() {
			this.isLoaded = false
			const res = await authenticatedApiFetch('/subscriptions/', { method: 'GET' })
				.then(response => response.json())
				.then(response => {
					return response[0]
				})

			if (res) {
				for await (const subscriptionResource of res.subscription_resource_level) {
					const res = await authenticatedApiFetch(`/subscription-resource-levels/${subscriptionResource}/`, { method: 'GET' })
						.then(response => response.json())
						.then(response => {
							return response
						})
					this.alreadyOwnedProducts.push({
						resourceType: res.resource_name,
						levels: res.levels_id,
						subscription_start: res.subscription_start,
						subscription_end: res.subscription_end,
					})
				}
			}
			this.isLoaded = true
		},
		checkIfAlreadyOwned(productName) {
			if (this.isLoaded) {
				const products = this.alreadyOwnedProducts.filter(x => x.resourceType == productName)
				if (products.length == 0) {
					return false
				}
				const currentDate = new Date()
				for (const product of products) {
					const endSubscriptionDate = new Date(product.subscription_end)
					if (endSubscriptionDate < currentDate) {
						console.log(product)
						return false
					}
				}
				return true
			}
		}

	},
	computed: {
		adjustedProducts() {
			const product = this.selectedProducts.map(item => {
				const { id, name, price_number, ...rest } = item
				console.log(name)
				console.log(id)
				console.log(price_number)
				return rest
			})
			return product

		}
	},

	created() {
		this.getProducts()
	},
	mounted() {
		this.getAlreadyOwnedProducts()
	},
}
</script>

<style>
</style>

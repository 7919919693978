<template>
	<div class="parameter-wrapper">
		<div class="subscription-container">
			<div class="header-container">
				<h2>
					<span v-if="alreadyOwnedProducts.length !== 0"
						>Licence enseignant</span
					>
					<span v-else>Gratuit enseignant</span>
				</h2>
			</div>
			<div class="content-container">
				<each-content
					v-for="(product, index) in products"
					:key="index"
					:product="product"
					:isAlreadyOwned="checkIfAlreadyOwned(product.title)"
					:subscriptionEnd="checkSubscriptionEnd(product.title)"
					:isFreeTrial="checkFreeTrialEndDate()"
					:cancelled="checkIfCancelled(product.title)"
					@refreshProducts="getAlreadyOwnedProducts()"
				/>
			</div>
			<div class="btn-container" v-if="alreadyOwnedProducts.length !== 0">
				<router-link :to="{ name: 'PriceList' }" class="submit dark">
					Modifier mon abonnement</router-link
				>
			</div>
			<div
				class="btn-container"
				v-else-if="!this.$store.state.freeTrial.free_trial_end"
			>
				<router-link :to="{ name: 'PriceList' }" class="submit dark">
					S'abonner</router-link
				>
				<button
					@click="isPopUpFreeTrialVisible = true"
					class="submit light"
				>
					Essai gratuit
				</button>
			</div>
			<div class="btn-container" v-else>
				<router-link :to="{ name: 'PriceList' }" class="submit dark">
					S'abonner</router-link
				>
			</div>
		</div>
		<pop-up-free-trial
			v-if="isPopUpFreeTrialVisible"
			@showPopUp="showPopUpToast"
			@close="isPopUpFreeTrialVisible = false"
		/>
	</div>
</template>

<script>
import { formatedDate } from '../../../utils'

import { getSubscriptionResourceLevelsByIdService } from '../../../services/subscriptionResourceLevelsService'
import { getSubscriptionService } from '../../../services/subscriptionsService'
import PopUpFreeTrial from '../../PopUp/Profile/PopUpFreeTrial.vue'
import EachContent from "./EachContent.vue"

export default {
	components: { EachContent, PopUpFreeTrial },
	data() {
		return {
			error: {
				text: '',
				status: ''
			},
			isPopUpFreeTrialVisible: false,
			products: [
				{
					img: '/img/payment/flash-icon.svg',
					title: 'Questions Flash',
					text: 'Préparez votre diaporama Flash en 3 min montre en main!',
					color: '#FFCC24'
				},
				{
					img: '/img/payment/expresso-icon.svg',
					title: 'Activités Expresso',
					text: 'Motivez vos élèves avec nos activités clés en main au design léché.',
					color: '#D11AFC'
				},
				{
					img: '/img/payment/game-icon.svg',
					title: 'Jeux Sérieux',
					text: 'Fiches d\'exercices ou d\'évaluation, réalisez votre document en un rien de temps… Puis vidéo-projetez ou imprimez !',
					color: '#59CA24'
				},
				{
					img: '/img/payment/classico-icon.svg',
					title: 'Exercices Classico',
					text: 'A la fin d\'un cours ou avant les vacances, captivez vos élèves et transmettez de façon ludique et pédagogique !',
					color: '#31C3D6'
				},
			],

			alreadyOwnedProducts: [],
			isLoaded: false
		}
	},

	methods: {
		checkIfCancelled(productName) {
			const product = this.alreadyOwnedProducts.filter(x => x.resourceType == productName)[0]
			if (product) {
				return product.status == 'cancelled'
			}
			return false
		},
		showPopUpToast(error) {
			this.isPopUpFreeTrialVisible = false
			this.$store.state.toast = error
		},

		checkFreeTrialEndDate() {
			const currentDate = new Date()
			if (this.$store.state.freeTrial.free_trial_end && this.$store.state.freeTrial.free_trial_start) {
				const freeTrialEndDate = new Date(this.$store.state.freeTrial.free_trial_end)

				// Obtenir la date actuelle

				// Comparer les dates
				if (freeTrialEndDate > currentDate) {
					return true
				}
				return false
			}
		},
		formatedDate: formatedDate,
		showPopUpCancelSubscription() {
			this.isPopUpCancelSubscriptionVisible = !this.isPopUpCancelSubscriptionVisible
		},
		async getAlreadyOwnedProducts() {
			this.isLoaded = false
			const res = await getSubscriptionService()

			if (res) {
				this.alreadyOwnedProducts = []
				for await (const subscriptionResource of res.subscription_resource_level) {
					const subscriptionResourceLevel = await getSubscriptionResourceLevelsByIdService(subscriptionResource)
					this.alreadyOwnedProducts.push({
						resourceType: subscriptionResourceLevel.resource_name,
						levels: subscriptionResourceLevel.levels_id,
						subscription_start: subscriptionResourceLevel.subscription_start,
						subscription_end: subscriptionResourceLevel.subscription_end,
						status: subscriptionResourceLevel.status,
					})
				}
			}
			this.isLoaded = true
		},
		checkIfAlreadyOwned(productName) {

			if (this.isLoaded) {
				const products = this.alreadyOwnedProducts.filter(x => x.resourceType == productName)
				if (products.length == 0) {
					return false
				}
				const currentDate = new Date()
				for (const product of products) {
					const endSubscriptionDate = new Date(product.subscription_end)
					if (endSubscriptionDate < currentDate) {
						return false
					}
				}
				return true
			}
		},
		checkSubscriptionEnd(productName) {
			const subscriptionEnd = this.alreadyOwnedProducts.filter(x => x.resourceType == productName)[0]
			if (subscriptionEnd) {

				return formatedDate(subscriptionEnd.subscription_end)
			}
			return ''
		}
	},
	mounted() {
		this.getAlreadyOwnedProducts()
	},
}
</script>

<style>
</style>

<template>
	<div :class="[added ? 'active' : '', checkGuest()]" class="fake-container">
		<div class="activite individuelle key">
			<div
				@click="showPopUpSlide = !showPopUpSlide"
				class="exo"
				ref="imgContainer"
			></div>
		</div>
		<div class="add-container add-container-key">
			<div>
				<p>{{ title }}</p>
			</div>
			<button @click="popUp" class="submit add">
				<span>Enregistrer</span>
			</button>
		</div>
	</div>
	<slider-key
		@addKey="addKey"
		@close="showPopUpSlide = !showPopUpSlide"
		v-if="showPopUpSlide"
		:image1="image1"
		:image2="image2"
		:image3="image3"
		:image4="image4"
		:image5="image5"
		:image6="image6"
		:image7="image7"
		:image8="image8"
		:image9="image9"
		:image10="image10"
	/>
	<pop-up-key v-if="isPopUpVisible" :id="index" @close="popUp" />
</template>

<script>
import { authenticatedImageFetch } from '../../../utils'
import PopUpKey from '../../PopUp/PopUpKey.vue'
import SliderKey from './SliderKey.vue'
export default {
	components: { SliderKey, PopUpKey },
	props: {
		index: Number,
		title: String,
		description: String,
		chapter: String,
		image1: String,
		image2: String,
		image3: String,
		image4: String,
		image5: String,
		image6: String,
		image7: String,
		image8: String,
		image9: String,
		image10: String,
		guest_visible: Boolean
	},
	data() {
		return {
			added: false,
			readyToDisplay: false,
			isPopUpVisible: false,
			showPopUpSlide: false,
			isVisibleGuest: false,
		}
	},
	methods: {
		addKey() {
			this.showPopUpSlide = false
			this.isPopUpVisible = true
		},
		checkGuest() {
			if (this.$store.state.user.role == 'GUEST') {
				return this.guest_visible ? '' : 'not-clickable'
			}
			else {
				return
			}
		},
		getImage() {
			authenticatedImageFetch(this.image1, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs[`imgContainer`].append(img)
					}
					reader.readAsDataURL(blob)
				})
		},
		popUp() {
			if (this.$store.state.user.role !== 'GUEST') {
				this.isPopUpVisible = !this.isPopUpVisible
			}
			else if (this.$store.state.user.role === 'GUEST') {
				this.isVisibleGuest = true
			}
		},
		timeToCloseGuest() {
			this.isVisibleGuest = false
		},
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
			}
		},
	},
	created() {
		this.reRender()
	},
	mounted() {
		this.reRender()
		setTimeout(() => {
			this.readyToDisplay = true
		}, 1000)

		if (this.image1 !== null) {
			this.getImage()
		}
	},
}
</script>

<style>
</style>

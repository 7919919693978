<template>
	<section class="create-calendar-container2">
		<h1>Création du calendrier de l'Avent</h1>
		<div class="calendar-wrapper">
			<div class="draggable-wrapper">
				
				<div class="input-container select-wrapper">
					<select v-model="level" >
						<option v-for="level in this.$store.getters.levels(this.$store.state.user.levels)" :key="level.id" :value="level.id">
							{{ level.name }} 
						</option>
					</select>
					<select v-model="subject" >
						<option v-for="subject in this.$store.getters.subjects(this.$store.state.user.subjects)" :key="subject.id" :value="subject.id">
							{{ subject.name }}
						</option>
					</select>
				</div>
				<div class="wrapper-wrapper">
					
					<div class="draggable-container">
						<h2>Cases cours</h2>
						<h3>Exercices</h3>
						<div
							@dragover.prevent
							@dragenter.prevent
							@drop="onDrop2($event, 1)"
							class="drop-zone"
						>
							<div
								class="draggable"
								v-for="item in listOne"
								:key="item.title"
								draggable="true"
								@dragstart="startDrag($event, item)"
								@click="showPopUp(item.id)"
							>
								<span>
									<div
										class="grab-indicator"
										:class="getItemType(item.type)"
									></div>
									<p>{{ item.title }}</p>
								</span>
							</div>
						</div>
						<h3>Enigmes</h3>
						<div
							@dragover.prevent
							@dragenter.prevent
							@drop="onDrop2($event, 2)"
							class="drop-zone"
						>
							<div
								class="draggable"
								v-for="item in listTwo"
								:key="item.title"
								draggable="true"
								@dragstart="startDrag($event, item)"
								@click="showPopUp(item.id)"
							>
								<span>
									<div
										class="grab-indicator"
										:class="getItemType(item.type)"
									></div>
									<p>{{ item.title }}</p>
								</span>
							</div>
						</div>
						<h3>Défis par équipe</h3>
						<div
							@dragover.prevent
							@dragenter.prevent
							@drop="onDrop2($event, 3)"
							class="drop-zone"
						>
							<div
								class="draggable"
								v-for="item in listThree"
								:key="item.title"
								draggable="true"
								@dragstart="startDrag($event, item)"
								@click="showPopUp(item.id)"
							>
								<span>
									<div
										class="grab-indicator"
										:class="getItemType(item.type)"
									></div>
									<p>{{ item.title }}</p>
								</span>
							</div>
						</div>
						
					</div>

					<div class="draggable-container">
						<h2>Cases à distance</h2>
						<h3>Enigmes</h3>
						<div
							@dragover.prevent
							@dragenter.prevent
							@drop="onDrop2($event, 5)"
							class="drop-zone"
						>
							<div
								class="draggable"
								v-for="item in listFive"
								:key="item.title"
								draggable="true"
								@dragstart="startDrag($event, item)"
								@click="showPopUp(item.id)"
							>
								<span>
									<div
										class="grab-indicator"
										:class="getItemType(item.type)"
									></div>
									<p>{{ item.title }}</p>
								</span>
							</div>
						</div>
						<h3>Messages pour le 25 Décembre</h3>
						<div
							@dragover.prevent
							@dragenter.prevent
							@drop="onDrop2($event, 6)"
							class="drop-zone"
						>
							<div
								class="draggable"
								v-for="item in listSix"
								:key="item.title"
								draggable="true"
								@dragstart="startDrag($event, item)"
								@click="showPopUp(item.id)"
							>
								<span>
									<div
										class="grab-indicator"
										:class="getItemType(item.type)"
									></div>
									<p>{{ item.title }}</p>
								</span>
							</div>
						</div>
						<h3>Vidéos</h3>
						<div
							@dragover.prevent
							@dragenter.prevent
							@drop="onDrop2($event, 7)"
							class="drop-zone"
						>
							<div
								class="draggable"
								v-for="item in listSeven"
								:key="item.title"
								draggable="true"
								@dragstart="startDrag($event, item)"
								@click="showPopUp(item.id)"
							>
								<span>
									<div
										class="grab-indicator"
										:class="getItemType(item.type)"
									></div>
									<p>{{ item.title }}</p>
								</span>
							</div>
						</div>
						<h3>Mes contenus</h3>
						<div
							@dragover.prevent
							@dragenter.prevent
							@drop="onDrop2($event, 8)"
							class="drop-zone"
						>
							<div
								class="draggable"
								v-for="item in listEight"
								:key="item.title"
								draggable="true"
								@dragstart="startDrag($event, item)"
								@click="showPopUp(item.id)"
							>
								<span>
									<div
										class="grab-indicator"
										:class="getItemType(item.type)"
									></div>
									<p>{{ item.title }}</p>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="btn-container">
					<button
						class="submit light"
						@click="
							isPopUpSuggestVisible =
								!isPopUpSuggestVisible
						"
					>
						Proposer un contenu
					</button>
				</div>
			</div>
			<div class="create-custom-calendar-container">
				<div class="guide-wrapper">
					<div class="guide-container">
						<button @click="showPopUpPrincipe" class="btn guide">
							Principe
						</button>
						<button @click="showPopUpGuide" class="btn guide">
							Mode d'emploi
						</button>
						<button @click="showPopUpSecularism" class="btn guide">
							Laïcité
						</button>
					</div>
				</div>
				<div
					:class="
						'create-custom-calendar-wrapper custom-calendar-wrapper-' +
						$route.params.template
					"
				>
					<div
						v-for="day in days"
						:key="day"
						class="calendar-day"
						@dragover.prevent
						@dragenter.prevent
						@drop="onDrop($event, day + 8)"
					>
						<div
							class="drag-el"
							:class="getItemType(item.type)"
							v-for="item in list(day + 8)"
							:key="item.title"
							draggable="true"
							@dragstart="startDrag($event, item)"
							@click="showPopUp(item.id)"
						>
							<p>{{ item.title }}</p>
						</div>
					</div>
				</div>
				<button class="submit dark" @click="submit">Valider</button>
			</div>
		</div>
		<pop-up-exo-calendar
			@close="closePopUp()"
			v-if="isPopUpVisible"
			:id="popUp.id"
			:title="popUp.title"
			:text="popUp.text"
			:video_url="popUp.video_url"
			:image_url="popUp.image_url"
		/>

		<pop-up-principe
			@close="showPopUpPrincipe()"
			v-if="isPopUpPrincipeVisible"
		/>
		<pop-up-guide-calendar
			@close="showPopUpGuide()"
			v-if="isPopUpGuideVisible"
		/>
		<pop-up-secularism
			@close="showPopUpSecularism()"
			v-if="isPopUpSecularismVisible"
		/>
		<pop-up-suggest-content
			v-if="isPopUpSuggestVisible"
			@close="isPopUpSuggestVisible = !isPopUpSuggestVisible"
			@addQuestion="allChristmasCalendarExercises"
		/>
		<pop-up-create-calendar
			v-if="isPopUpCreateCalendarVisible"
			:exoInCalendar="exoInCalendar"
			:level_id="level"
			@close="
				isPopUpCreateCalendarVisible = !isPopUpCreateCalendarVisible
			"
		/>
	</section>
</template>

<script>
import { checkRightFor } from '../../../../../utils'
import PopUpCreateCalendar from '../../../../PopUp/SeriousGame/Calendar/PopUpCreateCalendar.vue'
import PopUpExoCalendar from '../../../../PopUp/SeriousGame/Calendar/PopUpExoCalendar.vue'
import PopUpGuideCalendar from '../../../../PopUp/SeriousGame/Calendar/PopUpGuideCalendar.vue'
import PopUpPrincipe from '../../../../PopUp/SeriousGame/Calendar/PopUpPrincipe.vue'
import PopUpSecularism from '../../../../PopUp/SeriousGame/Calendar/PopUpSecularism.vue'
import PopUpSuggestContent from '../../../../PopUp/SeriousGame/Calendar/PopUpSuggestContent.vue'
import { allChristmasCalendarExercisesFilterService } from '../../../../../services/christmasCalendarExercisesService'
import { allLevelsService } from '../../../../../services/levelsService'
import { allSubjectsService } from '../../../../../services/sujectsService'

export default {
	components: {
		PopUpExoCalendar,
		PopUpPrincipe,
		PopUpGuideCalendar,
		PopUpSecularism,
		PopUpSuggestContent,
		PopUpCreateCalendar
	},

	data() {
		return {
			items: [],
			exo: [],
			days: 25,
			popUp: {},
			isPopUpVisible: false,
			isPopUpPrincipeVisible: false,
			isPopUpGuideVisible: false,
			isPopUpCalendarVisible: false,
			isPopUpSecularismVisible: false,
			calendarId: null,
			isPopUpSuggestVisible: false,
			isPopUpCreateCalendarVisible: false,
			exoInCalendar: [],
			levels: [],
			subjects: [],
			level: null,
			subject: null,
			isLoading: false
		}
	},

	methods: {
		getUserLevels() {
			const levels = this.$store.getters.levels(this.$store.state.user.levels)
			return levels
		},
		getUserSubjects() {
			const subjects = this.$store.getters.subjects(this.$store.state.user.subjects)
			return subjects
		},
		startDrag(evt, item) {
			evt.dataTransfer.dropEffect = 'move'
			evt.dataTransfer.effectAllowed = 'move'
			evt.dataTransfer.setData('itemID', item.id)
		},
		onDrop(evt, list) {
			const itemID = evt.dataTransfer.getData('itemID')
			const item = this.items.find(item => item.id == itemID)

			const alreadyFilled = this.items.find(item => item.list == list)
			if (alreadyFilled == undefined) {
				item.list = list
			}
		},
		onDrop2(evt) {
			const itemID = evt.dataTransfer.allChristmasCalendarExercises('itemID')
			const item = this.items.find(item => item.id == itemID)
			item.list = item.initialList
		},
		resetListValue(e, initialValue) {
			const item = this.items.find(item => item.id == e)
			item.list = initialValue
		},
		getItemType(type) {
			switch (type) {
				case 'Exercices':
					return 'exo-math'
				case 'Enigmes':
					return 'enigma'
				case 'Défis par équipe':
					return 'challenge'
				case 'Constructions géométriques':
					return 'geo'
				case 'Enigmes à distance':
					return 'enigma-distance'
				case 'Messages pour le 25 Décembre':
					return 'msg'
				case 'Vidéos à distance':
					return 'video'
			}
		},


		submit: async function () {
			if (!checkRightFor('Jeux Sérieux', [parseInt(this.$route.params.level_id)])) {
				this.$store.state.isPopUpNotAllowedVisible = true
				return
			}
			this.exoInCalendar = this.items.filter(item => item.list > 8)
			if (this.exoInCalendar.length !== 0) {
				this.isPopUpCreateCalendarVisible = !this.isPopUpCreateCalendarVisible
			}
			else {
				this.$store.state.toast = {
					text: 'Votre jeu est actuellement vide',
					status: 'error'
				}

			}
		},

		showPopUp(id) {
			this.popUp = this.items.find(x => x.id == id)
			this.isPopUpVisible = !this.isPopUpVisible
		},

		closePopUp() {
			this.isPopUpVisible = !this.isPopUpVisible
		},

		showPopUpPrincipe() {
			this.isPopUpPrincipeVisible = !this.isPopUpPrincipeVisible
		},
		showPopUpGuide() {
			this.isPopUpGuideVisible = !this.isPopUpGuideVisible
		},

		showPopUpCalendar() {
			this.isPopUpCalendarVisible = !this.isPopUpCalendarVisible
		},

		showPopUpSecularism() {
			this.isPopUpSecularismVisible = !this.isPopUpSecularismVisible
		},


		async allChristmasCalendarExercises() {
			this.items = []
			const filter = `levels=${this.level}&subjects=${this.subject}`
			const res = await allChristmasCalendarExercisesFilterService(filter)
			this.exo = res
			for (const exercise of this.exo) {
				if (exercise.teacher == this.$store.state.user.user) {
					exercise.list = 8
					exercise.initialList = 8
				}
				else {
					switch (exercise.type) {
						case 'Exercices':
							exercise.list = 1
							exercise.initialList = 1
							break
						case 'Enigmes':
							exercise.list = 2
							exercise.initialList = 2
							break
						case 'Défis par équipe':
							exercise.list = 3
							exercise.initialList = 3
							break
						case 'Constructions géométriques':
							exercise.list = 4
							exercise.initialList = 4
							break
						case 'Enigmes à distance':
							exercise.list = 5
							exercise.initialList = 5
							break

						case 'Messages pour le 25 Décembre':
							exercise.list = 6
							exercise.initialList = 6
							break

						case 'Vidéos à distance':
							exercise.list = 7
							exercise.initialList = 7
							break
					}
				}
				this.items.push(exercise)
			}
		}
	},

	computed: {
		listOne() {
			return this.items.filter(item => item.list === 1)
		},
		listTwo() {
			return this.items.filter(item => item.list === 2)
		},
		listThree() {
			return this.items.filter(item => item.list === 3)
		},
		listFour() {
			return this.items.filter(item => item.list === 4)
		},
		listFive() {
			return this.items.filter(item => item.list === 5)
		},
		listSix() {
			return this.items.filter(item => item.list === 6)
		},
		listSeven() {
			return this.items.filter(item => item.list === 7)
		},
		listEight() {
			return this.items.filter(item => item.list === 8)
		},
		list() {
			return index => {
				return this.items.filter(item => item.list === index)
			}
		},
	},
	async created() {
		this.isLoading = true
		this.levels = await allLevelsService()
		this.subjects = await allSubjectsService()
		this.level = JSON.parse(localStorage.getItem('profile')).levels[0]
		this.subject = JSON.parse(localStorage.getItem('profile')).subjects[0]
		await this.allChristmasCalendarExercises()
		this.isLoading = false
	},

	watch: {
		level() {
			if(!this.isLoading){
				this.allChristmasCalendarExercises()
			}
		},
		subject() {
			if(!this.isLoading){
				this.allChristmasCalendarExercises()
			}
		},
		
	}

}
</script>



<template>
	<div class="tile-create-container">
		<img
			class="wander-math-logo"
			src="/img/game/tiles/wander-math.svg"
			alt=""
		/>
		<div class="grid-question-container">
			<div class="question-container">
				<p class="intro-text">
					Déposez les questions dans les cases et crée votre jeu&nbsp;!
				</p>
				<div class="input-container select-wrapper">
					<select v-model="subject" >
						<option v-for="subject in subjects" :key="subject.id" :value="subject.id">
							{{ subject.name }}
						</option>
					</select>
					<select v-model="level" >
						<option v-for="level in levels" :key="level.id" :value="level.id">
							{{ level.name }} 
						</option>
					</select>
				</div>
				<div class="grabbable-container">
					<div
						class="grabbable-wrapper"
						@dragover.prevent
						@dragenter.prevent
						@drop="onDrop2($event, 1)"
					>
						<h3> <img src="/img/game/tiles/timer.svg" alt=""> Flash</h3>
						<div class="draggable-container">
							<div
								class="draggable"
								v-for="question in listOne"
								:key="question"
								draggable="true"
								@dragstart="startDrag($event, question)"
								@click="showPopUp(question)"
							>
								<span>
									<div class="grab-indicator flash"></div>
									<p>{{ question.title }}</p>
								</span>
							</div>
						</div>
					</div>
					<div
						class="grabbable-wrapper"
						@dragover.prevent
						@dragenter.prevent
						@drop="onDrop2($event, 2)"
					>
						<h3><img src="/img/game/tiles/puzzle.svg" alt="">Enigma</h3>
						<div class="draggable-container">
							<div
								class="draggable"
								v-for="question in listTwo"
								:key="question"
								draggable="true"
								@dragstart="startDrag($event, question)"
								@click="showPopUp(question)"
							>
								<span>
									<div class="grab-indicator enigma"></div>
									<p>{{ question.title }}</p>
								</span>
							</div>
						</div>
					</div>

					<div
						class="grabbable-wrapper"
						@dragover.prevent
						@dragenter.prevent
						@drop="onDrop2($event, 3)"
					>
						<h3> <img src="/img/game/tiles/boomerang.svg" alt="">Boomerang</h3>
						<div class="draggable-container">
							<div
								class="draggable"
								v-for="question in listThree"
								:key="question"
								draggable="true"
								@dragstart="startDrag($event, question)"
								@click="showPopUp(question)"
							>
								<span>
									<div class="grab-indicator boomerang"></div>
									<p>{{ question.title }}</p>
								</span>
							</div>
						</div>
					</div>

					<div
						class="grabbable-wrapper"
						@dragover.prevent
						@dragenter.prevent
						@drop="onDrop2($event, 4)"
					>
						<h3><img src="/img/game/tiles/target.svg" alt="">OneShot</h3>
						<div class="draggable-container">
							<div
								class="draggable"
								v-for="question in listFour"
								:key="question"
								draggable="true"
								@dragstart="startDrag($event, question)"
								@click="showPopUp(question)"
							>
								<span>
									<div class="grab-indicator one-shot"></div>
									<p>{{ question.title }}</p>
								</span>
							</div>
						</div>
					</div>
					<div
						class="grabbable-wrapper"
						@dragover.prevent
						@dragenter.prevent
						@drop="onDrop2($event, 5)"
					>
						<h3><img src="/img/game/tiles/interogation.svg" alt="">Chance</h3>
						<div class="draggable-container">
							<div
								class="draggable"
								v-for="question in listFive"
								:key="question"
								draggable="true"
								@dragstart="startDrag($event, question)"
								@click="showPopUp(question)"
							>
								<span>
									<div
										class="grab-indicator hit-or-miss"
									></div>
									<p>{{ question.title }}</p>
								</span>
							</div>
						</div>
					</div>
					<div
						class="grabbable-wrapper"
						@dragover.prevent
						@dragenter.prevent
						@drop="onDrop2($event, 6)"
					>
						<h3>Mes questions</h3>
						<div class="draggable-container">
							<div
								class="draggable"
								v-for="question in listSix"
								:key="question"
								draggable="true"
								@dragstart="startDrag($event, question)"
							>
								<span>
									<div
										class="grab-indicator"
										:class="
											getClassName(
												question.tile_game_type_name
											)
										"
									></div>
									<div class="delete-container">
										<p @click="showPopUp(question)">
											{{ question.title }}
										</p>
										<button
											@click="removeQuestion(question.id)"
										>
											<img src="/img/close.svg" alt="" />
										</button>
									</div>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="btn-container">
					<button
						class="submit light"
						@click="popUpSuggestIsVisible = !popUpSuggestIsVisible"
					>
						Proposer une question
					</button>
				</div>
			</div>
			<Vue3Lottie
				class="lottie"
				:animationData="lottie"
				:loop="true"
				:autoplay="true"
				:width="100"
			/>
			<div class="grid-template-container">
				
				<ul class="hex-grid__list">
					<li
						:class="this.$route.params.theme"
						class="hex-grid__item"
						v-for="item in 25"
						:key="item"
					>
						<div
							class="hex-grid__content"
							@dragover.prevent
							@dragenter.prevent
							@drop="onDrop($event, item + 5)"
						>
							<div
								class="draggable"
								draggable="true"
								@dragstart="
									startDrag($event, getItem(item + 5))
								"
								:class="getItem(item + 5).tile_game_type_name"
								@click="showPopUp(getItem(item + 5))"
							>
								<span>{{ getItem(item + 5).title }} </span>
							</div>
						</div>
					</li>
				</ul>
				<div class="btn-container">
					<button
						@click="popUpRuleVisible = true"
						class="submit light"
					>
						Principe
					</button>
					<button class="submit dark" @click="submit">
						Créer le jeu
					</button>
				</div>
			</div>
		</div>
		<pop-up-exo
			v-if="isPopUpVisible"
			@showPopUp="showPopUp(selectedQuestion)"
			:question="selectedQuestion"
			@close="isPopUpVisible = !isPopUpVisible"
		/>

		<pop-up-rule-tile
			v-if="popUpRuleVisible"
			@close="popUpRuleVisible = !popUpRuleVisible"
			@next="
				(popUpDeroulementVisible = !popUpDeroulementVisible),
					(popUpRuleVisible = !popUpRuleVisible)
			"
		/>
		<pop-up-deroulement-tile
			v-if="popUpDeroulementVisible"
			@prev="
				(popUpDeroulementVisible = !popUpDeroulementVisible),
					(popUpRuleVisible = !popUpRuleVisible)
			"
			@close="popUpDeroulementVisible = !popUpDeroulementVisible"
		/>
		<pop-up-suggest-content
			v-if="popUpSuggestIsVisible"
			@close="popUpSuggestIsVisible = !popUpSuggestIsVisible"
			@addQuestion="allQuestions"
		/>
		<pop-up-create-tile-game
			v-if="isPopUpCreateTileVisible"
			@close="isPopUpCreateTileVisible = !isPopUpCreateTileVisible"
			:exoInCalendar="exoInCalendar"
		/>
	</div>
</template>

<script>

import { checkRightFor, getTileGameInfo } from '../../../../../utils'
import PopUpCreateTileGame from '../../../../PopUp/SeriousGame/TileGame/PopUpCreateTileGame.vue'
import PopUpDeroulementTile from '../../../../PopUp/SeriousGame/TileGame/PopUpDeroulementTile.vue'
import PopUpExo from '../../../../PopUp/SeriousGame/TileGame/PopUpExo.vue'
import PopUpRuleTile from '../../../../PopUp/SeriousGame/TileGame/PopUpRuleTile.vue'
import PopUpSuggestContent from '../../../../PopUp/SeriousGame/TileGame/PopUpSuggestContent.vue'
import { allTileGameContentsFilterService, deleteTileGameContentService } from '../../../../../services/tileGameContentsService'
import { allTileGameTypesService } from '../../../../../services/tileGameTypesService'
import { allTileGameThemesService } from '../../../../../services/tileGameThemesService'
import {Vue3Lottie} from 'vue3-lottie'
import lottie from '../TileGame/lottie/drag-drop.json'
export default {
	components: {
		PopUpExo,
		PopUpRuleTile,
		PopUpDeroulementTile,
		PopUpSuggestContent,
		PopUpCreateTileGame,
		Vue3Lottie
	},
	data() {
		return {
			lottie,
			levels: [],
			subjects: [],
			questions: [],
			tilesGameTypes: [],
			items: [],
			classe: {},
			isPopUpVisible: false,
			selectedQuestion: {},
			exoInCalendar: [],
			popUpRuleVisible: false,
			popUpDeroulementVisible: false,
			popUpSuggestIsVisible: false,
			isPopUpCreateTileVisible: false,
			themes: [],
			level: null,
			subject: null,
			isLoading: false
		}
	},
	methods: {
		async removeQuestion(id) {
			if (!confirm('Voulez-vous vraiment supprimer cette question ?')) {
				return
			}
			const res = await deleteTileGameContentService(id)
			if (res.ok) {
				this.questions = this.questions.filter(x => x.id !== id)
				this.items = this.items.filter(x => x.id !== id)
			}
		},
		getTileGameInfo: getTileGameInfo,
		getClassName(name) {
			switch (name) {
				case 'Flash':
					return 'flash'
				case 'Boomerang':
					return 'boomerang'
				case 'HitOrMiss':
					return 'hit-or-miss'
				case 'Enigma':
					return 'enigma'
				case 'OneShot':
					return 'one-shot'
			}
		},

		getUserLevels() {
			const levels = this.$store.getters.levels(this.$store.state.user.levels)
			return levels
		},
		getUserSubjects() {
			const subjects = this.$store.getters.subjects(this.$store.state.user.subjects)
			return subjects
		},

		async submit () {
			if (!checkRightFor('Jeux Sérieux', [parseInt(this.$route.params.level_id)])) {
				this.$store.state.isPopUpNotAllowedVisible = true
				return
			}
			this.exoInCalendar = this.items.filter(item => item.list > 6)
			if (this.exoInCalendar.length !== 0) {
				this.isPopUpCreateTileVisible = !this.isPopUpCreateTileVisible
			}
			else {
				this.$store.state.toast = {
					text: 'Votre jeu est actuellement vide',
					status: 'error'
				}
			}
		},

		showPopUp(e) {
			if(typeof e === 'object'){
				this.selectedQuestion = e
				this.isPopUpVisible = !this.isPopUpVisible
			}
			else{
				this.selectedQuestion = null
				this.isPopUpVisible = !this.isPopUpVisible
			}
		},
		getItem(e) {
			let item = this.items.find(x => x.list == e)

			if (item) {
				return item
			}
			else {
				return '+'
			}
		},
		startDrag(evt, item) {
			evt.dataTransfer.dropEffect = 'move'
			evt.dataTransfer.effectAllowed = 'move'
			evt.dataTransfer.setData('itemID', item.id)
		},
		onDrop(evt, list) {
			const itemID = evt.dataTransfer.getData('itemID')
			const item = this.items.find(item => item.id == itemID)
			let isAlreadyFill = this.items.find(item => item.list == list)
			if (!isAlreadyFill) {
				item.list = list
			}
		},
		onDrop2(evt) {
			const itemID = evt.dataTransfer.getData('itemID')
			const item = this.items.find(item => item.id == itemID)
			item.list = item.initialList
		},

		
		async allTileGameType() {
			this.tilesGameTypes = await allTileGameTypesService()
		},
		getThemeId() {
			const theme = this.themes.find(x => x.name == this.$route.params.theme)
			if (theme){
				return theme.id
			}
			else {
				return null
			}
		},


		async allTileGameThemes() {
			this.themes = await allTileGameThemesService()
		},
		async allQuestions () {
			this.isLoading = true
			const keepedItems = this.items.filter(item => item.list > 6)
			this.items = []
			this.questions = []
			const themeId = this.getThemeId()
			const levelFilter = this.level ? `levels=${this.level}` : ''
			const tileGameThemeFilter = themeId ? `tile_game_theme=${themeId}` : ''
			const subjectFilter = this.subject ? `subjects=${this.subject}` : ''

			const filter = `${levelFilter}&status=PUBLIC&${tileGameThemeFilter}&${subjectFilter}`
			const res = await allTileGameContentsFilterService(filter)

			const filter2 = `${levelFilter}&teacher=${this.$store.state.user.user}&${subjectFilter}`
			const res2 = await allTileGameContentsFilterService(filter2)

			this.questions = [...res, ...res2]

			this.questions = this.questions.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)

			for (let i = 0; i < this.questions.length; i++) {
				if (this.questions[i].teacher == this.$store.state.user.user) {
					this.questions[i].list = 6
					this.questions[i].initialList = 6
				}
				else {
					switch (this.questions[i].tile_game_type_name) {
						case 'Flash':
							this.questions[i].list = 1
							this.questions[i].initialList = 1
							break
						case 'Enigma':
							this.questions[i].list = 2
							this.questions[i].initialList = 2
							break
						case 'Boomerang':
							this.questions[i].list = 3
							this.questions[i].initialList = 3
							break
						case 'OneShot':
							this.questions[i].list = 4
							this.questions[i].initialList = 4
							break
						case 'HitOrMiss':
							this.questions[i].list = 5
							this.questions[i].initialList = 5
							break
					}
				}
				this.items.push(this.questions[i])
			}
			if (this.items.length !== 0) {
				
				for(const item of keepedItems){
					let findedItem = this.items.find(x => x.id == item.id)
					if (findedItem) {
						findedItem.list = item.list
					}
				}
			}

			this.items = [...this.items, ...keepedItems]
			this.items = this.items.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)

			this.isLoading = false
		},
		async getQuestions(){
			this.levels = this.getUserLevels()
			this.subjects = this.getUserSubjects()

			if (this.levels) {
				this.level = this.levels[0].id
			}
			if (this.subjects) {
				this.subject = this.subjects[0].id
			}
			await this.allQuestions()
		}
	},

	computed: {
		listOne() {
			return this.items.filter(item => item.list === 1)
		},
		listTwo() {
			return this.items.filter(item => item.list === 2)
		},
		listThree() {
			return this.items.filter(item => item.list === 3)
		},
		listFour() {
			return this.items.filter(item => item.list === 4)
		},
		listFive() {
			return this.items.filter(item => item.list === 5)
		},
		listSix() {
			return this.items.filter(item => item.list === 6)
		},
		list() {
			return index => {
				return this.items.filter(item => item.list === index)
			}
		},
	},


	async mounted(){
		await this.allTileGameType()
		await this.allTileGameThemes()
		await this.getQuestions()
	},


	watch: {
		level: async function () {
			if (!this.isLoading){ 
				await this.allQuestions()
			}
		},
		subject: async function () {
			if (!this.isLoading){ 
				await this.allQuestions()
			}
		},
		
	}
}
</script>

<style>
</style>
